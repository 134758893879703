//sticky nav
const lastScrollTop = 80;

$(window).scroll(function () {
  const st = $(this).scrollTop();
  if (st > lastScrollTop && 0 <= lastScrollTop) {
    $('body').addClass('nav-sticky');
  } else {
    $('body').removeClass('nav-sticky');
  }
});
