const scrollToTopBtn = document.querySelector('.site-footer__top-page-btn');

scrollToTopBtn.addEventListener('click', () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

//progress bar

const progressBar = document.querySelector('#progressBar');
const section = document.querySelector('body');

const scrollProgressBar = () => {
  const scrollDistance = -section.getBoundingClientRect().top;
  const progressPercentage =
    (scrollDistance /
      (section.getBoundingClientRect().height -
        document.documentElement.clientHeight)) *
    100;

  const val = Math.floor(progressPercentage);
  progressBar.style.width = val + '%';

  if (val < 0) {
    progressBar.style.width = '0%';
  }
};

window.addEventListener('scroll', scrollProgressBar);

document.addEventListener('facetwp-loaded', function () {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});
