import { isElementInViewport } from '../common/utils';

document.addEventListener('DOMContentLoaded', function () {
  const scrollElements = document.querySelectorAll('.animatedScroll');

  const addClassToScrollElement = (element) => {
    element.classList.add('scrolled');
  };

  const handleScrollAnimation = () => {
    scrollElements.forEach((el) => {
      if (isElementInViewport(el)) {
        addClassToScrollElement(el);
      }
    });
  };

  window.addEventListener('scroll', () => {
    handleScrollAnimation();
  });
  window.addEventListener('load', () => {
    handleScrollAnimation();
  });
});

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth',
    });
  });
});
